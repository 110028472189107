<template>
<div class="org-analytics">
  <div class="org-analytics__header align-center">
    <div class="d-flex align-center">
      <h3 class="mr-7">
        Организация <span>«{{ tenant.company_name }}»</span>
      </h3>
      <EsFastDatesRange />
    </div>
    <div class="d-flex align-center">
      <EsDatePickerRange :dateFrom="dateFrom" :dateTo="dateTo" @pickDates="pickDates" @clearDates="clearDates"/>
      <div class="excel-buttons ml-1">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="evi-button-green export mr-2" v-if="hasRight('analytics.export')" v-on="on" :bind="attrs">
              <img class="mr-2" width="23" :src="require('@/assets/img/common/export.svg')" alt="">
              Экспорт
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group>
              <v-list-item
                  v-for="(format, index) in importFormats"
                  :key="index"
                  class="pointer"
                  @click="downloadData(format)"
              >
                <v-list-item-icon class="evi-sidebar__icon">
                  <img width="16" :src="require(`@/assets/img/analytics/excel.svg`)" alt=""/>
                </v-list-item-icon>
                <v-list-item-title class="v-list-item__title">Экспортировать .{{format}}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
  <div v-if="loading" class="org-analytics__cards w-100">
    <v-skeleton-loader
        class="org-analytics__card w-100"
        v-for="i in 6" :key="i"
    />
  </div>
  <div v-else class="org-analytics__cards">
    <div class="org-analytics__card">
      <span class="name">Выручка ₽</span>
      <img :src="require('@/assets/img/analytics/org/payments-black.svg')" v-if="darkTheme" alt="">
      <img :src="require('@/assets/img/analytics/org/payments.svg')" v-else alt="">
      <span class="value">{{ organization.sales_amount | VMask(currencyMask)}}</span>
    </div>
    <div class="org-analytics__card">
      <span class="name">Клиентов всего</span>
      <img :src="require('@/assets/img/analytics/org/clients-black.svg')" v-if="darkTheme" alt="">
      <img :src="require('@/assets/img/analytics/org/clients.svg')" v-else alt="">
      <span class="value">{{ organization.total_clients_count }}</span>
    </div>
    <div class="org-analytics__card">
      <span class="name">Клиентов, учитываемых в конверсии</span>
      <img :src="require('@/assets/img/analytics/org/clients-black.svg')" v-if="darkTheme" alt="">
      <img :src="require('@/assets/img/analytics/org/clients.svg')" v-else alt="">
      <span class="value">{{ organization.processed_clients_count }}</span>
    </div>
    <div class="org-analytics__card">
      <span class="name">Клиентов, не учитываемых в конверсии</span>
      <img :src="require('@/assets/img/analytics/org/clients-black.svg')" v-if="darkTheme" alt="">
      <img :src="require('@/assets/img/analytics/org/clients.svg')" v-else alt="">
      <span class="value">{{ organization.unprocessed_clients_count }}</span>
    </div>
    <div class="org-analytics__card">
      <span class="name">Конверсия</span>
      <img :src="require('@/assets/img/analytics/org/conversion-black.svg')" v-if="darkTheme" alt="">
      <img :src="require('@/assets/img/analytics/org/conversion.svg')" v-else alt="">
      <span class="value">{{ Number.isInteger(organization.conversion) ? organization.conversion: parseFloat(organization.conversion).toFixed(2) }}%</span>
    </div>
    <div class="org-analytics__card">
      <span class="name">Количество <br> сделок</span>
      <img :src="require('@/assets/img/analytics/org/deals-black.svg')" v-if="darkTheme" alt="">
      <img :src="require('@/assets/img/analytics/org/deals.svg')" v-else alt="">
      <span class="value">{{ organization.total_deals_count }}</span>
    </div>
  </div>
</div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {hasRight} from "@/utils/access/hasRight";
import EsDatePickerRange from "@/components/сommon/EsTable/EsDatePickerRange";
import {eventBus} from "@/main";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import exportData from "@/utils/files/requestExport";
import EsFastDatesRange from "@/components/сommon/EsTable/EsFastDatesRange";

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowNegative: true,
});

export default {
  name: "OrganizationAnalytics",
  components: {EsFastDatesRange, EsDatePickerRange},
  data: () => ({
    importFormats: ['xlsx'],
    dateFrom: '',
    dateTo: '',
    fastPeriod: [
      {
        name: 'Сегодня',
        dateFrom: new Date(),
        dateTo: new Date(),
      },
      {
        name: 'Неделя',
        dateFrom: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        dateTo: new Date(),
      },
      {
        name: 'Месяц',
        dateFrom: new Date().setMonth(new Date().getMonth()-1),
        dateTo: new Date(),
      },
      {
        name: 'Год',
        dateFrom: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        dateTo: new Date(),
      },
    ],
    darkTheme: false,
    currencyMask,
  }),
  computed: {
    ...mapState("user", ["tenant"]),
    ...mapState("common", ["loading"]),
    ...mapState("analytics", ["organization"]),
  },
  methods: {
    ...mapActions({
      getOrganizationAnalytics: 'analytics/getOrganizationAnalytics',
    }),
    pickDates(from, to) {
      this.dateFrom = this.$moment(from).format('YYYY-MM-DD');
      this.dateTo = this.$moment(to).format('YYYY-MM-DD');

      this.getOrganizationAnalytics(
          {
            date_from: this.$moment(from).format('YYYY-MM-DD'),
            date_to: this.$moment(to).format('YYYY-MM-DD')
          }
      );
      this.updateQuery();
    },
    clearDates () {
      this.dateFrom = '';
      this.dateTo = '';
    },
    hasRight: hasRight,
    downloadData(exportType) {
      let args = {
        export: exportType ? exportType : true,
      };
      if (this.dateTo && this.dateFrom) {
        args['date_from'] = this.dateFrom;
        args['date_to'] = this.dateTo;
      }

      exportData(this.$store.dispatch, 'analytics/downloadReport', {
        name: "organization",
        args: args
      }, `Аналитика по организации с ${this.dateFrom} по ${this.dateTo}`);
    },
    updateQuery () {
      this.$router.replace({
        query: {
          date_from: this.dateFrom,
          date_to: this.dateTo,
        },
      }).catch(() => {});
    },
    queryInit() {
      this.dateFrom = this.$route.query['date_from'] || '';
      this.dateTo = this.$route.query['date_to'] || '';
      if (!this.dateFrom) {
        this.dateFrom = this.fastPeriod[0].dateFrom;
        this.dateTo = this.fastPeriod[0].dateTo;
      }
      this.pickDates(this.dateFrom, this.dateTo);
      this.updateQuery();
    }
  },
  mounted () {
    this.queryInit();

    eventBus.$on('toggleDarkTheme', (payload) => {
      this.darkTheme = payload === "true";
    });
    this.darkTheme = localStorage.getItem("dark_theme") === "true";
  }
}
</script>

<style lang="scss" scoped>
.org-analytics {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 60px 25px;
  margin-bottom: 25px;

  h3 {
    font-weight: bold;
    font-size: 20px;
    margin-left: 23px;
    color: #C377FF;

    span {
      text-transform: uppercase;
    }
  }

  &__header {
    margin-bottom: 41px;
    display: flex;
    justify-content: space-between;

    .excel-buttons {
      margin-right: 33px;
      margin-top: 1px;
    }
  }

  &__cards {
    margin-left: 23px;
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
  }

  &__card {
    border-radius: 12px;
    background: linear-gradient(149deg, #F2F2F2 27.83%, #EBF5F9 81.36%);
    width: 269px;
    height: 190px;
    position: relative;
    padding: 17px;
    align-items: flex-end;
    display: flex;

    img {
      width: 108px;
    }

    .name {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 33px;
      color: $evi-headline-color;
      position: absolute;
      top: 25px;
      left: 17px;
    }

    .value {
      text-align: right;
      font-style: normal;
      font-weight: 600;
      color: $evi-headline-color;
      max-width: 239px;
      white-space: nowrap;
      font-size: 32px;
      position: absolute;
      right: 20px;
      bottom: 10px;
    }
  }
}
</style>
